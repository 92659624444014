<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/integralGoods' }">积分商城</el-breadcrumb-item>
        <el-breadcrumb-item>商品详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="shebei">基本信息</div>
        <el-form
            :model="upData"
            label-position="rihgt"
            label-width="130px"
            class="content_li-form content_li-padding"
        >
          <el-form-item label="商品名称：">{{ upData.commodityName }}</el-form-item>
          <el-form-item label="商品大分类：">{{ upData.advertiseId | getAdvertise }}</el-form-item>
          <el-form-item label="商品小分类：">{{ upData.smallAdvertiseId | getSmallAdvertise }}</el-form-item>
          <el-form-item label="商品类型：">{{ upData.typeName }}</el-form-item>
          <el-form-item label="商品物流：">{{ upData.logistics }}</el-form-item>
          <el-form-item label="商品服务：">
            <el-tag class="tag-mr" v-for="(item,index) in upData.configurationAssInfos" :key="index" type="success">
              {{ item.content }}
            </el-tag>
          </el-form-item>
          <el-form-item label="运费模板：">{{ upData.templateTitle }}</el-form-item>
          <el-form-item label="商品运费：" v-if="false">{{ upData.freight }}元</el-form-item>
          <el-form-item label="状态：">
            <el-tag class="tag-mr" v-if="upData.commodityStatus === 2" type="success">上架</el-tag>
            <el-tag class="tag-mr" v-if="upData.commodityStatus === 1" type="error">下架</el-tag>
          </el-form-item>
          <el-form-item label="排序：">{{ upData.sort }}</el-form-item>
          <el-form-item label="商品图片：">
            <div class="flexRow1">
              <div class="up-image">
                <div v-for="(item, index) in upData.commodityImgs" class="image-list" :key="index">
                  <img @click="handleSee(item)" :src="item" alt/>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="shebei">商品Sku表</div>
        <el-table
            border
            :data="upData.attrsAssociateInfos"
            class="content_li-padding"
            style="width: 80%"
            :header-cell-style="this.utils.getRowClass"
        >
          <el-table-column align="center" label="名称" prop="combinationName"></el-table-column>
          <el-table-column align="center" label="商品规格图">
            <template slot-scope="scope"><img @click="handleSee(scope.row.images)" class="icon-img"
                                              :src="scope.row.images"/></template>
          </el-table-column>
          <el-table-column align="center" label="售价">
            <template slot-scope="scope">{{ (scope.row.sellingPrice / 100).toFixed(2) }}元</template>
          </el-table-column>
          <el-table-column align="center" label="库存" prop="stock"></el-table-column>
          <el-table-column align="center" label="重量(KG)">
            <template slot-scope="scope" v-if="scope.row.weight!==0">{{ scope.row.weight }}KG</template>
          </el-table-column>
        </el-table>
        <div class="shebei">商品描述</div>
        <div class="info-style editor-content">
          <!--          <div :html="upData.commodityDesc"></div>-->
          <rich-text-editor class="editor content_li-padding" v-model="upData.commodityDesc"></rich-text-editor>
        </div>
      </div>
    </div>

    <div class="footer-toolbar">
      <el-button @click="$router.go(-1)" type="primary">返回</el-button>
    </div>

    <picture-dialog :url="dialogImageUrl" :visible.sync="dialogVisible"/>

  </div>
</template>

<script>
import RichTextEditor from "../../../richTextEditor";

let _this;
export default {
  components: {RichTextEditor},
  data() {
    _this = this;
    return {
      upData: {},
      goodsId: '',
      dialogImageUrl: '',
      dialogVisible: false,
      categoriesInfoList: [],
      smallAdvertiseList: [],
    };
  },
  filters: {
    getAdvertise(value) {
      const data = _this.categoriesInfoList.filter(item => item.id == value);
      if (data && data.length > 0) {
        return data[0].categoriesName;
      }
    },
    getSmallAdvertise(value) {
      const data = _this.smallAdvertiseList.filter(item => item.id == value);
      if (data && data.length > 0) {
        return data[0].smallAdvertise;
      }
    }
  },
  mounted() {
    if (this.$route.query.data) {
      this.upData = JSON.parse(decodeURIComponent(this.$route.query.data));
      this.getSmallCategoriesInfoData(this.upData.advertiseId);
    }
  },
  created() {
    this.getAllCategoriesInfoData();
  },
  methods: {
    // 获取商品大分类
    getAllCategoriesInfoData() {
      let _this = this;
      _this.$api.getAllCategoriesInfo().then(res => {
        if (res.data.code == 200) {
          _this.categoriesInfoList = res.data.data;
        }
      });
    },
    // 根据大分类ID获取商品小分类
    getSmallCategoriesInfoData(id) {
      let _this = this;
      _this.$api.getSmallById({id}).then(res => {
        if (res.data.code == 200) {
          _this.smallAdvertiseList = res.data.data;
        }
      });
    },
    handleSee(url) {
      let _this = this;
      _this.dialogImageUrl = url;
      _this.dialogVisible = true;
    },
  }
};
</script>
<style scoped lang="less">
.shebei {
  padding: 20px 30px;
  font-weight: 900;
  font-size: 14px;
  background: #f7fafc;
}

.content_li {
  padding: 0;
}
.content_li-padding {
  margin: 30px;
  box-sizing: border-box;
}
.content_li-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 2;
}

.el-form-item {
  width: 50%;
}
.footer-toolbar {
  position: fixed;
  bottom: 0;
  z-index: 100;
  //left: 0;
  right: 0;
  width: 89.5%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-top: 2px solid #eee;
  padding: 10px 40px;
  box-sizing: border-box;
  text-align: right;
}
.tag-mr {
  margin-right: 10px;
}
</style>
